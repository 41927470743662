import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import MeetTheInsightersImage from '../assets/MeetTheInsightersImage.png';
import MobileMeetTheInsighters from '../assets/MobileMeetTheInsighters.png';
import MediumOrnament from '../../common/MediumOrnament';
import HoverButton from '../../common/HoverButton';

const OpportunityOverview: React.FC = (props) => {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== windowWidth) {
        setWindowWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return windowWidth <= 960 ? (
    <Grid container spacing={4} style={{ maxWidth: 400, margin: 'auto', width: '100%' }}>
      <Grid item xs={12} style={{ marginBottom: 10, cursor: 'pointer' }}>
        <a href="mailto:hello@theinsighters.com">
          <img
            src={MobileMeetTheInsighters}
            alt="Meet the Insighters"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </a>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={4} style={{ paddingLeft: '12%', paddingRight: '12%' }}>
      <Grid item xs={12} md={6} style={{ marginBottom: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <MediumOrnament text="About Us" centerText centerComponent={windowWidth <= 960} />
          </Grid>
          <Grid item xs={12}>
            <h2
              style={{
                color: '#3B4255',
                fontFamily: 'Crimson Text',
                fontSize: '28px',
                fontStyle: 'normal',
                lineHeight: '150%',
                fontWeight: 600,
                marginBottom: 5,
                paddingBottom: 5,
              }}
            >
              Meet The Insighters
            </h2>
          </Grid>
          <Grid item xs={12}>
            <p style={{ fontFamily: 'Rethink Sans', fontWeight: 450, lineHeight: '1.2', fontSize: '1.6rem' }}>
              This research is sponsored by a company that makes FDA-approved treatments.
            </p>
            <br />
            <p style={{ fontFamily: 'Rethink Sans', lineHeight: '1.2', fontSize: '1.6rem' }}>
              The Insighters® is a healthcare research recruitment platform backed by Sommer Consulting, a woman-owned
              small business with 40 years of experience in healthcare & life sciences insights research.
            </p>
            <br />
            <p style={{ fontFamily: 'Rethink Sans', lineHeight: '1.2', fontSize: '1.6rem' }}>
              The Insighters® platform matches you with paid research opportunities that are tailored to your insights.
              We make it easier for you to be at the forefront of industry, share your expertise, & get paid.
            </p>
          </Grid>
          <Grid style={{ marginTop: 40 }}>
            <HoverButton text="Explore Our Story" link="/about" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src={MeetTheInsightersImage}
          alt="opportunity"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </Grid>
    </Grid>
  );
};

export default OpportunityOverview;

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import OtherPartnersCarousel from '../components/OtherPartnersCarousel';
import SectionOrnament from '../../common/SectionOrnament';
import MediumOrnament from '../../common/MediumOrnament';
import TechnologyPartners from '../components/TechnologyPartners';
import LightBulbIcon from '../../../../assets/redesignAssets/staticImages/LightbulbIcon.png';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    marginBottom: 15,
    textAlign: 'center',
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 40,

    [theme.breakpoints.down(960)]: {
      padding: 0,
    },
  },
}));

const TrustedPartnersSection: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <Grid container style={{ paddingLeft: 30, paddingRight: 30, maxWidth: 1400, margin: 'auto' }}>
      <Grid item xs={12} md={6}>
        <img src={LightBulbIcon} alt="Light Bulb Icon" style={{ width: '100%', height: 'auto' }} />
      </Grid>
      <Grid item xs={12} md={6} className={customClasses.contentWrapper}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <MediumOrnament text="We work with the best" centerText centerComponent={window.innerWidth <= 960} />
          </Grid>
          <Grid item xs={12}>
            <Typography className={`${classes.h2} ${customClasses.title}`}>Our Trusted Technology</Typography>
            <Typography className={`${classes.h2} ${customClasses.title}`} style={{ color: '#488C7A' }}>
              Partners
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TechnologyPartners />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography className={classes.smallText}>OTHER PARTNERS</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.hrLine} />
          </Grid>
          <Grid item xs={12}>
            <OtherPartnersCarousel />
          </Grid>
          {/* There is not currently a 'partnerships' page, so we'll hide this button for now */}
          {/* <Grid item xs={12} style={{ marginTop: 20 }}>
            <HoverButton text="Explore Our Partnerships" />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrustedPartnersSection;

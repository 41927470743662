import React, { useState } from 'react';
import { Grid, Button, IconButton, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterBarPaintSmear from '../../resourcesPage/assets/FilterBarPaintSmear.png';
import useBaseStyles from '../../constants/redesignStyles';

const filterLinks = [{ label: 'All', category: undefined }];

const useStyleOverrides = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& .MuiFilledInput-root': {
      borderRadius: '20px',
      backgroundColor: '#407667',
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
  h2: {},
}));

interface HomepageResourcesFilterBarProps {
  fetchOpportunities: (category?: string, query?: string) => void;
}

const HomepageResourcesFilterBar: React.FC<HomepageResourcesFilterBarProps> = (props) => {
  const { fetchOpportunities } = props;
  const baseClasses = useBaseStyles();
  const overrideClasses = useStyleOverrides();

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [useFilter, setFilter] = useState<boolean>(false);

  const handleCategoryClicked = (category: string | undefined) => {
    setSelectedCategory(category);
    fetchOpportunities(category, searchQuery);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={`${baseClasses.h2} ${overrideClasses.h2}`} style={{ fontWeight: 600 }}>
          Get Paid for Research - <span style={{ color: '#488C7A' }}>Your Insights Matter</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          className={baseClasses.smallText}
          style={{ lineHeight: '1.5em', fontWeight: 500, textAlign: 'center', marginTop: 20 }}
        >
          Discover how your stories can spark positive change in healthcare.
        </Typography>
      </Grid>
      <br />
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            backgroundImage: `url(${FilterBarPaintSmear})`,
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: 80,
            maxWidth: 1200,
            margin: 'auto',
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              paddingLeft: '10%',
              paddingRight: '10%',
            }}
          >
            {window.innerWidth >= 960 &&
              filterLinks.map((filterLink) => (
                <Grid
                  item
                  key={filterLink.label}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {selectedCategory === filterLink.category ? (
                    <>
                      <Button
                        style={{ backgroundColor: '#F3C66B' }}
                        onClick={() => handleCategoryClicked(filterLink.category)}
                      >
                        <p
                          style={{
                            color: 'black',
                            fontSize: 14,
                            fontFamily: 'Rethink Sans, sans-serif',
                            textTransform: 'capitalize',
                            fontWeight: 700,
                          }}
                        >
                          {filterLink.label}
                        </p>
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => handleCategoryClicked(filterLink.category)}>
                      <p
                        style={{
                          color: 'white',
                          fontSize: 14,
                          fontFamily: 'Rethink Sans, sans-serif',
                          textTransform: 'capitalize',
                          fontWeight: 700,
                        }}
                      >
                        {filterLink.label}
                      </p>
                    </Button>
                  )}
                </Grid>
              ))}
            {window.innerWidth >= 960 && (
              <Grid item xs={3}>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  defaultValue=""
                  variant="filled"
                  size="small"
                  className={overrideClasses.root}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default HomepageResourcesFilterBar;

import React from 'react';
import HoverButtonArrow from '../../../assets/redesignAssets/HoverButtonArrow.png';

interface ShortHoverButtonProps {
  text: string;
  onSubmit: () => void;
}

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    height: '40px',
    transition: 'background-color 0.3s ease',
    background: '#F3C66B',
    borderRadius: '120px',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    padding: '0 20px',
  },
  hoverButtonText: {
    fontFamily: 'Rethink Sans',
    fontWeight: 'bold' as const,
  },
  image: {
    position: 'absolute' as const,
    right: '10px', // Start near the right edge of the button
    width: '25px',
    height: '25px',
    transition: 'transform 0.3s ease',
  },
  imageHover: {
    transform: 'translateX(-105px)', // Move the image to the left edge on hover
  },
};

const ShortHoverButton: React.FC<ShortHoverButtonProps> = (props) => {
  const [isHovered, setIsHovered] = React.useState(false);

  let { text, onSubmit } = props;

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button
      type="button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styles.button}
      onClick={onSubmit}
    >
      <span style={styles.hoverButtonText}>{text}</span>
    </button>
  );
};

export default ShortHoverButton;

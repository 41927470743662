import React from 'react';
import { useHistory } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../../../assets/logo.png';
import LoginSplat from '../../../assets/redesignAssets/staticImages/LogInSplat.png';
import Users from '../../../assets/redesignAssets/staticImages/Users.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
      display: 'none',
      [theme.breakpoints.up(360)]: {
        display: 'inline-block',
      },
    },
  }),
);

const styles = {
  navMenuButton: {
    cursor: 'pointer',
    lineHeight: '60px',
    width: 'auto',
    background: 'none',
    border: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
    height: '100%',
  },
  paidOpportunityText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Rethink Sans',
    fontSize: 10,
    letterSpacing: '0.5px',
    width: 'auto',
    padding: 0,
    textTransform: 'uppercase' as const,
  },
  navMenuText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 700,
    fontFamily: 'Crimson Text',
    fontSize: 20,
    letterSpacing: '-0.2px',
    width: 'auto',
    padding: 0,
    marginTop: '-4px',
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
};

interface RedesignTopNavigationProps {
  mobileNavigationActive: any;
  setMobileNavigationActive: any;
}

const RedesignTopNavigation: React.FC<RedesignTopNavigationProps> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      className="Polaris-TopBar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        boxShadow: 'none',
        background: 'inherit',
      }}
    >
      <Grid item>
        <button
          type="button"
          className="Polaris-TopBar__NavigationIcon"
          aria-label="Toggle menu"
          onClick={() => setMobileNavigationActive(true)}
          style={{ marginTop: 7 }}
        >
          <span className="Polaris-Icon">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
            </svg>
          </span>
        </button>
      </Grid>
      <Grid item md={3}>
        <button type="button" style={{ ...styles.buttonLink, float: 'left' }} onClick={() => history.push('/')}>
          <img alt="logo" src={Logo} className={classes.logo} style={{ float: 'left' }} />
        </button>
      </Grid>
      <Grid item md={9}>
        <Grid container justifyContent="space-between">
          <Hidden smDown>
            <Grid item style={{ maxWidth: '600px', width: '80%' }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/general')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={styles.navMenuText}>For Everyone</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/professionals')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={styles.navMenuText}>For Healthcare Professionals</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/about')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.navMenuText}>About</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/resources')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.navMenuText}>
                      Resources
                      {/* <KeyboardArrowDownIcon
                        style={{
                          transform: 'rotate(0deg)',
                          transition: 'transform 0.3s ease-in-out',
                          fontWeight: 'bold',
                          position: 'relative',
                          top: 4,
                          left: 2,
                        }}
                      /> */}
                    </Typography>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container onClick={() => history.push('/login')}>
              <Grid
                item
                style={{
                  backgroundImage: `url(${LoginSplat})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '50px',
                  width: '50px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  padding: 8,
                  marginTop: 4,
                  marginRight: 10,
                }}
              >
                <img src={Users} alt="Users" style={{ width: '80%', height: 'auto' }} />
              </Grid>
              <Grid item>
                <p style={styles.navMenuButton}>Log In</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </AppBar> */}
    </Grid>
  );
};

export default RedesignTopNavigation;

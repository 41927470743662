import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import MaryFoxBio from '../assets/MaryFoxBio.png';
import AlyssaBio from '../assets/AlyssaBio.png';
import AmyBio from '../assets/AmyBio.png';
import TenaBio from '../assets/TenaBio.png';
import TinaBio from '../assets/TinaBio.png';

const useCustomStyles = makeStyles((theme) => ({
  bioStyles: {
    width: '100%',
    height: 'auto',
    objectFit: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.2) 5px 5px 15px',
    borderRadius: 8,

    [theme.breakpoints.down(960)]: {
      objectFit: 'contain',
      boxShadow: 'none',
    },
  },
  bioContainer: {
    maxHeight: 300,

    [theme.breakpoints.down(960)]: {
      marginBottom: 20,
    },
  },
}));

const InsightersTeamBios: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <Grid container justifyContent="center" style={{ maxWidth: 1200, margin: 'auto' }}>
      <MediumOrnament text="Meet Our Team" centerComponent centerText />
      <Typography className={classes.h2} style={{ textAlign: 'center' }}>
        The Real People Behind The Insighters
      </Typography>
      <br />
      <Grid container style={{ marginTop: 15 }} spacing={2}>
        <Grid item md={6} xs={12} className={customClasses.bioContainer}>
          <img
            alt="employee-headshot"
            src="https://cdn.theinsighters.com/MaryFoxBio.png"
            className={customClasses.bioStyles}
          />
        </Grid>
        <Grid item md={6} xs={12} className={customClasses.bioContainer}>
          <img
            alt="employee-headshot"
            src="https://cdn.theinsighters.com/TinaBio.png"
            className={customClasses.bioStyles}
          />
        </Grid>
        <Grid item md={6} xs={12} className={customClasses.bioContainer}>
          <img
            alt="employee-headshot"
            src="https://cdn.theinsighters.com/TenaBio.png"
            className={customClasses.bioStyles}
          />
        </Grid>
        <Grid item md={6} xs={12} className={customClasses.bioContainer}>
          <img
            alt="employee-headshot"
            src="https://cdn.theinsighters.com/AmyBio.png"
            className={customClasses.bioStyles}
          />
        </Grid>
        <Grid item md={3} />
        <Grid item md={6} xs={12} className={customClasses.bioContainer}>
          <img
            alt="employee-headshot"
            src="https://cdn.theinsighters.com/AlyssaBio.png"
            className={customClasses.bioStyles}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsightersTeamBios;

import React from 'react';
import { useHistory } from 'react-router-dom';
import HoverButtonArrow from '../../../assets/redesignAssets/HoverButtonArrow.png';

interface HoverButtonProps {
  text: string;
  link?: string;
  onClick?: () => void;
  extraLong?: boolean;
  externalLink?: boolean;
}

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    height: '45px',
    minHeight: '45px',
    transition: 'background-color 0.3s ease',
    background: '#F3C66B',
    borderRadius: '120px',
    border: 'none',
    cursor: 'pointer',
    padding: '0 20px',
  },
  hoverButtonText: {
    textDecoration: 'none !important',
    fontFamily: 'Rethink Sans',
    color: '#3B4255',
    fontWeight: 800,
    fontSize: '15px',
    letterSpacing: '0.5px',
  },
  image: {
    position: 'absolute' as const,
    right: '10px', // Start near the right edge of the button
    width: '30px',
    height: '30px',
    transition: 'transform 0.3s ease',
  },
  imageHover: {
    transform: 'translateX(-200px)', // Move the image to the left edge on hover
  },
};

const HoverButton: React.FC<HoverButtonProps> = (props) => {
  let { text, link, onClick, extraLong = false, externalLink = true } = props;

  const history = useHistory();
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [buttonWidth, setButtonWidth] = React.useState(text.length > 25 ? 300 : 250);

  return (
    <button
      type="button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styles.button}
      onClick={() => {
        if (externalLink) {
          window.location.href = link;
        } else if (onClick) {
          onClick();
        } else {
          history.push(link);
        }
      }}
    >
      <span style={styles.hoverButtonText}>{text}</span>
    </button>
  );
};

export default HoverButton;

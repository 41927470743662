import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OpportunitiesListHero from './sections/OpportunitiesListHero';
import OpportunitiesFilterBar from './sections/OpportunitiesFilterBar';
import OpportunitiesGallery from './sections/OpportunitiesGallery';
import RedesignFooter from '../common/RedesignFooter';

import { Opportunity } from '../../opportunities/model';

import axios from '../../utils/axios.utils';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 30,

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const GeneralOpportunitiesListPage: React.FC = () => {
  let classes = useStyles();

  let [generalOpportunities, setGeneralOpportunities] = useState<Opportunity[]>([]);

  const fetchGeneralOpportunities = async () => {
    try {
      const response = await axios.get('/insighter_opportunities/general');
      setGeneralOpportunities(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGeneralOpportunities();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesListHero heroImageUrl="https://cdn.theinsighters.com/OpportunitiesHero.png" />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesFilterBar fetchOpportunities={() => null} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ display: 'flex', justifyContent: 'center' }}>
        <OpportunitiesGallery redesignOpportunities={generalOpportunities} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default GeneralOpportunitiesListPage;

import React, { useContext, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

// import BlogAuthorDetailHero from './sections/BlogAuthorDetailHero';
import MediumOrnament from '../../common/MediumOrnament';
import { BlogAuthor } from '../../../blogPosts/model';

import siteWideStyles from '../../../styles/siteWideStyles';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 15,
  },
});

interface BlogAuthorViewerProps {
  blogAuthor: BlogAuthor;
}

const BlogAuthorViewer: React.FC<BlogAuthorViewerProps> = (props) => {
  let { blogAuthor } = props;

  return (
    <Grid container style={{ maxWidth: '1200px', margin: 'auto' }} justifyContent="space-between">
      <Grid item xs={12} md={6} style={{ alignItems: 'center', display: 'flex' }}>
        <Grid container>
          <Grid item xs={7}>
            <MediumOrnament text="About the Author" width="200px" centerComponent={false} centerText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ fontFamily: 'Crimson Text', marginBottom: 10, fontWeight: 'bold' }}>
              Meet {blogAuthor?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{ fontSize: 18, fontFamily: 'Crimson Text', width: '100%' }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: blogAuthor?.bio }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
        <img
          src={blogAuthor?.s3_image_url}
          alt="author"
          style={{ width: 'auto', height: '500px', objectFit: 'cover', display: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
};

export default BlogAuthorViewer;
